import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Dialog } from '../shared/elements'
import { TextField, Checkbox } from '../shared/fields'
import * as API from '../../services/api'

export default function FormNewUserDialog(props) {
  const { show, toggle, setUsers } = props

  const [isSubmitting, setSubmitting] = useState(false)
  const [error, setError] = useState('')

  const form = useForm({
    defaultValues: {
      name: '',
      info: {
        position: '',
      },
      email: '',
      password: '',
      passwordConfirmation: '',
      permissions: {
        reports: true,
        offers: true,
        users: true,
        account: true,
        api: true,
      },
    },
  })

  const onSubmit = (values) => {
    if (isSubmitting) {
      return
    }

    setError('')
    setSubmitting(true)

    API.postRequest('/advertiser/advertiser_users', {
      advertiserUser: values,
    })
      .then((response) => {
        setSubmitting(false)

        if (response?.data?.success) {
          if (response?.data?.users) {
            setUsers(response.data.users)
            toggle(false)
          }
        } else {
          setError(response?.message || 'Something went wrong')
        }
      })
      .catch((error) => {
        setSubmitting(false)
        setError(error.message)
      })
  }

  const reset = (event) => {
    event.preventDefault()
    form.reset()
    toggle(false)
  }

  return (
    <Dialog show={show} toggle={toggle}>
      <form onSubmit={form.handleSubmit(onSubmit)} autoComplete="off">
        <h4 className="mt--25 mb-30">New user</h4>
        <div className="row">
          <div className="col-md-6">
            <TextField
              form={form}
              validation={{ required: true }}
              idPrefix="newuser_"
              type="text"
              name="name"
              label="Full name"
            />
          </div>
          <div className="col-md-6">
            <TextField
              form={form}
              validation={{ required: true }}
              idPrefix="newuser_"
              type="text"
              name="info.position"
              label="Position"
            />
          </div>
          <div className="col-md-6">
            <TextField
              form={form}
              validation={{ required: true }}
              idPrefix="newuser_"
              type="email"
              name="email"
              label="E-mail"
              autocomplete="off"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <TextField
              form={form}
              validation={{ required: true }}
              idPrefix="newuser_"
              type="password"
              name="password"
              label="Password"
              autocomplete="new-password"
            />
          </div>
          <div className="col-md-6">
            <TextField
              form={form}
              validation={{
                required: true,
                validate: (value) => value === form.getValues().password,
              }}
              idPrefix="newuser_"
              type="password"
              name="passwordConfirmation"
              label="Password confirmation"
              autocomplete="new-password"
            />
          </div>
        </div>
        <div>
          <h6 className="mb-3">Permissions</h6>
          <Checkbox
            form={form}
            name="permissions.reports"
            label="Reporting"
            wrapperClassName="mb-2"
          />
          <Checkbox
            form={form}
            name="permissions.offers"
            label="Offer Management"
            wrapperClassName="mb-2"
          />
          <Checkbox
            form={form}
            name="permissions.users"
            label="User Management"
            wrapperClassName="mb-2"
          />
          <Checkbox
            form={form}
            name="permissions.account"
            label="Account Management"
            wrapperClassName="mb-2"
          />
          <Checkbox
            form={form}
            name="permissions.api"
            label="API Access"
            wrapperClassName="mb-2"
          />
        </div>

        {error && <div className="text-danger mb-3">{error}</div>}

        <div className="form-actions text-right">
          <button disabled={isSubmitting} className="btn btn-primary mr-3">
            Submit
          </button>
          <button className="btn btn-secondary" onClick={reset}>
            Cancel
          </button>
        </div>
      </form>
    </Dialog>
  )
}
