import React, { useState } from 'react'
import { conversionStatusMessages } from '../../utils/constants'
import {
  capitalize,
  formatTimeToAction,
  formatMoney,
} from '../../utils/helpers'

export default function ConversionsTableRow(props) {
  const {
    conversion,
    updateConversionStatus,
    selectedConversions,
    toggleConversionSelect,
  } = props

  const contract = conversion.contract
  const publisher = conversion.publisher

  return (
    <tr>
      <td className="spacer" />
      <td className="px-0">
        <input
          type="checkbox"
          checked={selectedConversions.includes(conversion.tune_event_id)}
          onChange={(e) => toggleConversionSelect(conversion.tune_event_id)}
        />
      </td>
      <th>{conversion.ad_id}</th>
      <th>{conversion.advertiser_info}</th>
      <th>{publisher && publisher.name}</th>
      <th>{contract?.code}</th>
      <td className="with-separator">{conversion.datetime}</td>
      <td className="with-separator">{formatTimeToAction(conversion)}</td>
      <td className="with-separator">
        <ApproveAction
          conversion={conversion}
          updateConversionStatus={updateConversionStatus}
        />
      </td>
      <td>{conversionStatusMessages[conversion.status_code]}</td>
      <td className="with-separator text-right">
        {formatMoney(conversion.net_payout, conversion.currency)}
      </td>
      <td className="with-separator text-right">
        {formatMoney(conversion.net_revenue, conversion.currency)}
      </td>
      <td className="with-separator text-right">
        {formatMoney(conversion.net_sale_amount, conversion.currency)}
      </td>
      <td className="text-left">
        {conversion.refer && (
          <a href={conversion.refer} target="_blank" rel="noreferrer">
            {conversion.refer}
          </a>
        )}
      </td>
      <td className="spacer" />
    </tr>
  )
}

const ApproveAction = (props) => {
  const { conversion, updateConversionStatus } = props

  const [isSubmitting, setIsSubmitting] = useState(false)

  const action = conversion.status === 'approved' ? 'reject' : 'approve'
  const status = conversion.status === 'approved' ? 'rejected' : 'approved'

  const onClick = async (event) => {
    event.preventDefault()

    if (isSubmitting) {
      return
    }

    setIsSubmitting(true)

    updateConversionStatus(conversion.tune_event_id, status)
      .then((response) => {
        setIsSubmitting(false)
      })
      .catch((error) => {
        setIsSubmitting(false)
      })
  }

  return (
    <>
      {capitalize(conversion.status)}
      <span className="approve-action">
        (
        <button className="a" onClick={onClick}>
          {capitalize(action)}
        </button>
        )
      </span>
    </>
  )
}
