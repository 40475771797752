import React, { useState } from 'react'
import styled from 'styled-components'
import SVG from 'react-inlinesvg'
import FormBillingDialog from './FormBillingDialog'
import InvoiceList from './InvoiceList'
import {
  PageTitle,
  PageContent,
  PageLoading,
  AddPaymentMethodDialog,
} from '../shared/elements'
import { useApiLoad } from '../shared/hooks'
import { deleteRequest } from '../../services/api'
import { getCountryName } from '../../utils/countries'
import { formatAddress, capitalize } from '../../utils/helpers'

const Container = styled.div`
  .actions {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
    top: 16px;
    right: 12px;

    button {
      display: inline-block;
      height: 30px;
      padding: 4px 12px;
      margin-bottom: 8px;
      border: none;
      border-radius: 15px;
      color: white;
      background: var(--btn-background);
      vertical-align: middle;
    }
  }

  .label {
  }

  .value {
    margin-bottom: 8px;
    font-weight: 500;
    line-height: 20px;

    &.bold {
      margin-bottom: 24px;
      font-weight: 700;
    }
  }

  .payment-method {
    margin-bottom: 4px;

    &:hover {
      .remove-payment-method-btn {
        opacity: 0.5;
      }
    }

    .remove-payment-method-btn {
      position: relative;
      top: -1px;
      padding-left: 4px;
      opacity: 0;
      transition: opacity 0.3s;

      &:hover {
        opacity: 1;
      }

      svg {
        width: 9px;
      }
    }
  }
`

export default function Billing(props) {
  const [showEdit, setShowEdit] = useState(false)
  const [showAddPaymentMethod, setShowAddPaymentMethod] = useState(false)

  const [advertiser, setAdvertiser] = useState(null)
  const [wallets, setWallets] = useState(null)
  const [paymentMethods, setPaymentMethods] = useState(null)
  const [invoices, setInvoices] = useState(null)
  useApiLoad('/advertiser/billing', advertiser === null, (response) => {
    setAdvertiser(response?.data?.advertiser)
    setWallets(response?.data?.wallets)
    setPaymentMethods(response?.data?.paymentMethods)
    setInvoices(response?.data?.invoices)
  })

  const reloadData = () => {
    setAdvertiser(null)
  }

  const removePaymentMethod = (paymentMethodId) => async () => {
    if (window.confirm('Are you sure?')) {
      await deleteRequest(`/advertiser/billing/methods/${paymentMethodId}`)

      reloadData()
    }
  }

  const loading = advertiser === null || wallets === null || invoices === null
  if (loading) {
    return <PageLoading title="Billing" />
  }

  return (
    <Container>
      <PageTitle title="Billing" />

      <PageContent>
        <div className="panel mb-4">
          <div className="actions">
            <button
              className="a btn btn-secondary"
              onClick={() => {
                setShowEdit(true)
              }}
            >
              Edit billing information
            </button>
            <button
              className="a btn btn-secondary"
              onClick={() => {
                setShowAddPaymentMethod(true)
              }}
              hidden
            >
              Add payment method
            </button>
          </div>

          <div className="row">
            <div className="col-sm-5 col-md-4">
              <div className="label">Payment methods</div>
              <div className="value bold">
                {paymentMethods?.map((paymentMethod) => (
                  <div key={paymentMethod.id} className="payment-method">
                    {capitalize(paymentMethod.cardBrand)}{' '}
                    {paymentMethod.cardFunding} card {paymentMethod.cardLast4}
                    <button
                      className="a remove-payment-method-btn"
                      onClick={removePaymentMethod(paymentMethod.id)}
                    >
                      <SVG src="/images/icons/times-fat.svg" />
                    </button>
                  </div>
                ))}
                <div>Invoice</div>
              </div>
              <div className="label">Company name</div>
              <div className="value">{advertiser.billing?.name}</div>
              <div className="label">Address</div>
              <div className="value">{formatAddress(advertiser.billing)}</div>
              <div className="label">Country</div>
              <div className="value">
                {getCountryName(advertiser.billing.country || '')}
              </div>
              <div className="label">VAT</div>
              <div className="value">{advertiser.company.vat}</div>
            </div>
            <div className="col-sm-5 col-md-4">
              <div className="label">Invoice frequency</div>
              <div className="value bold">Monthly</div>
              <div className="label">Other details</div>
              <div className="value">{advertiser.billing.other}</div>
            </div>
          </div>
        </div>

        <div className="panel mb-4">
          <InvoiceList invoices={invoices} />
        </div>

        <FormBillingDialog
          show={showEdit}
          toggle={setShowEdit}
          advertiser={advertiser}
          setAdvertiser={setAdvertiser}
        />

        <AddPaymentMethodDialog
          show={showAddPaymentMethod}
          toggle={setShowAddPaymentMethod}
          onDone={reloadData}
        />
      </PageContent>
    </Container>
  )
}
