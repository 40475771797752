import React, { useState } from 'react'
import { compact } from 'lodash'
import styled from 'styled-components'
import SVG from 'react-inlinesvg'
import FormUserDialog from './FormUserDialog'
import FormNewUserDialog from './FormNewUserDialog'
import FormCompanyDialog from './FormCompanyDialog'
import { PageTitle, PageContent, PageLoading } from '../shared/elements'
import { useApiLoad } from '../shared/hooks'
import { formatDatetime } from '../../utils/helpers'
import { getCountryName } from '../../utils/countries'

const Container = styled.div`
  .actions {
    margin-bottom: 8px;

    h4 {
      display: inline-block;
      margin-bottom: 0;
      vertical-align: middle;
    }

    button {
      height: 30px;
      padding: 4px 10px;
      margin-left: 8px;
      border: none;
      border-radius: 15px;
      color: var(--btn-color);
      background: var(--btn-background);
      vertical-align: middle;
    }

    svg {
      width: 18px;
      color: white;
    }
  }

  .user-info {
    margin-top: 12px;

    .label {
      text-transform: uppercase;
      font-size: 12px;
      margin-bottom: -2px;
    }

    .value {
      margin-bottom: 8px;
    }
  }

  .permission {
    svg {
      width: 16px;
      opacity: 0;
    }

    &.active {
      svg {
        opacity: 1;
      }
    }
  }

  .permissions,
  .activities,
  .address,
  .contacts {
    padding: 8px 0;
  }

  .permission {
    padding: 4px 0px;

    svg {
      margin-top: -4px;
      margin-right: 6px;
    }
  }

  .activities,
  .contacts {
    .label {
      font-style: italic;
      font-size: 12px;
    }

    .value {
      margin-bottom: 6px;
    }
  }

  .users {
    padding: 8px 16px;
    border: 1px solid var(--panel-border);
    border-radius: 4px;
    background: white;

    table {
      thead {
        th {
          border-left-width: 1px;
          border-right-width: 1px;

          &:first-of-type {
            border-left-width: 0;
          }

          &:last-of-type {
            border-right-width: 0;
          }
        }
      }

      tbody > tr:nth-child(even) > td {
        background: var(--input-background);
      }

      tfoot {
        td {
          border: none;
        }
      }
    }
  }
`

const Info = styled.div`
  > div {
    border-left: 1px solid #b8b8b8;
    border-right: 1px solid #b8b8b8;

    b {
      display: block;
      padding: 8px 12px;
      margin-left: -12px;
      margin-right: -12px;
      border-bottom: 1px solid #b8b8b8;
    }
  }

  > div + div {
    border-left: none;
  }
`

export default function Account(props) {
  const [showUser, setShowUser] = useState(false)
  const [showCompany, setShowCompany] = useState(false)
  const [showNewUser, setShowNewUser] = useState(false)

  const [user, setUser] = useState(null)
  const [users, setUsers] = useState(null)
  const [advertiser, setAdvertiser] = useState(null)
  useApiLoad('/advertiser/account', user === null, (response) => {
    setUser(response.data?.user)
    setUsers(response.data?.users)
    setAdvertiser(response.data?.advertiser)
  })

  const permissionClass = (permission) => {
    if (user.permissions && user.permissions[permission]) {
      return 'permission active'
    } else {
      return 'permission'
    }
  }

  const loading = !user || !users || !advertiser
  if (loading) {
    return <PageLoading title="My account" />
  }

  return (
    <Container>
      <PageTitle title="My account" />

      <PageContent>
        <div className="actions">
          <h4>User details</h4>
          <button onClick={() => setShowUser(true)}>
            <SVG src="/images/icons/pencil.svg" />
          </button>
          <button
            className="a btn btn-secondary"
            onClick={() => setShowUser(true)}
          >
            Change password
          </button>
        </div>

        <div className="row">
          <div className="col-sm-4">
            <b>{user.name}</b>
            <div className="user-info">
              <div className="label">Email</div>
              <div className="value">{user.email}</div>
              <div className="label">Office phone</div>
              <div className="value">{user.info?.officePhone}</div>
              <div className="label">Mobile phone</div>
              <div className="value">{user.info?.mobilePhone}</div>
              <div className="label">Receive network emails</div>
              <div className="value">
                {user.info?.networkEmails ? 'Yes' : 'No'}
              </div>
            </div>
          </div>

          <div className="col-sm-8">
            <Info className="row">
              <div className="col-sm-6">
                <b>Permissions</b>
                <div className="permissions">
                  <div className={permissionClass('reports')}>
                    <SVG src="/images/icons/check.svg" />
                    Reporting
                  </div>
                  <div className={permissionClass('offers')}>
                    <SVG src="/images/icons/check.svg" />
                    Offer Management
                  </div>
                  <div className={permissionClass('users')}>
                    <SVG src="/images/icons/check.svg" />
                    User Management
                  </div>
                  <div className={permissionClass('account')}>
                    <SVG src="/images/icons/check.svg" />
                    Account Management
                  </div>
                  <div className={permissionClass('api')}>
                    <SVG src="/images/icons/check.svg" />
                    API Access
                  </div>
                </div>
              </div>

              <div className="col-sm-6">
                <b>Activity</b>
                <div className="activities">
                  <div className="label">Sign up date</div>
                  <div className="value">{formatDatetime(user.createdAt)}</div>
                  <div className="label">Last modified</div>
                  <div className="value">{formatDatetime(user.updatedAt)}</div>
                  <div className="label">Last login</div>
                  <div className="value">
                    {user.loginAt ? formatDatetime(user.loginAt) : 'Never'}
                  </div>
                </div>
              </div>
            </Info>
          </div>
        </div>

        <div className="actions mt-4">
          <h4>Company details</h4>
          <button onClick={() => setShowCompany(true)}>
            <SVG src="/images/icons/pencil.svg" />
          </button>
        </div>

        <div className="row">
          <div className="col-sm-4">
            <b>{advertiser.name}</b>
            <div className="user-info">
              <div className="label">Tax ID</div>
              <div className="value">{advertiser.company?.vat}</div>
              <div className="label">Affiliate ID</div>
              <div className="value">{advertiser.tuneAdvertiserId}</div>
            </div>
          </div>

          <div className="col-sm-8">
            <Info className="row">
              <div className="col-sm-6">
                <b>Address</b>
                <div className="address">
                  <div>{advertiser.company.address}</div>
                  <div>
                    {compact([
                      advertiser.company.city,
                      advertiser.company.zip,
                    ]).join(', ')}
                  </div>
                  <div>{getCountryName(advertiser.company.country || '')}</div>
                </div>
              </div>

              <div className="col-sm-6">
                <b>Contacts</b>
                <div className="contacts">
                  <div className="label">Office phone</div>
                  <div className="value">{advertiser.company.officePhone}</div>
                  <div className="label">Fax</div>
                  <div className="value">{advertiser.company.fax}</div>
                </div>
              </div>
            </Info>
          </div>
        </div>

        <div className="actions mt-4">
          <h4>Manage users</h4>
          <button
            className="a btn btn-secondary"
            onClick={() => {
              setShowNewUser(true)
            }}
          >
            Create new
          </button>
        </div>

        <div className="users mb-5">
          <table className="table table-responsive bg-white">
            <thead>
              <tr>
                <th>Full name</th>
                <th>Title</th>
                <th>Email</th>
                <th>Last login</th>
                <th>Last modified</th>
              </tr>
            </thead>
            <tbody>
              {users.map((user) => (
                <tr key={user.uuid}>
                  <td>{user.name}</td>
                  <td>{user.info.position}</td>
                  <td>{user.email}</td>
                  <td>
                    {user.loginAt ? (
                      formatDatetime(user.loginAt)
                    ) : (
                      <span className="text-muted">Never</span>
                    )}
                  </td>
                  <td>{formatDatetime(user.updatedAt)}</td>
                </tr>
              ))}
            </tbody>
            <tfoot>
              <tr>
                <td>&nbsp;</td>
              </tr>
            </tfoot>
          </table>
        </div>

        <FormUserDialog
          show={showUser}
          toggle={setShowUser}
          user={user}
          setUser={setUser}
        />

        <FormCompanyDialog
          show={showCompany}
          toggle={setShowCompany}
          advertiser={advertiser}
          setAdvertiser={setAdvertiser}
        />

        <FormNewUserDialog
          show={showNewUser}
          toggle={setShowNewUser}
          setUsers={setUsers}
        />
      </PageContent>
    </Container>
  )
}
