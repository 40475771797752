import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import MaskedInput from 'react-text-mask'
import SVG from 'react-inlinesvg'
import { directPaginationStyles } from '../shared/elements'

const Container = styled.div`
  ${directPaginationStyles}
`

export default function Pagination(props) {
  const { page, pages, setPage } = props

  const [showPage, setShowPage] = useState(page)
  const gotoPage = (newPage) => {
    if (newPage > 0 && newPage <= pages) {
      setPage(newPage)
    } else {
      setShowPage(page)
    }
  }

  useEffect(() => {
    setShowPage(page)
  }, [page])

  if (pages <= 1) {
    return null
  }

  return (
    <Container>
      <button className="prev" onClick={() => gotoPage(Number(page) - 1)}>
        <SVG src="/images/icons/arrow-left.svg" />
      </button>
      <div className="page text-primary">
        Page
        <MaskedInput
          className={Number(showPage) > 99 ? 'large' : ''}
          mask={Array(3).fill(/\d/)}
          guide={false}
          value={showPage}
          onChange={(event) => {
            setShowPage(event.target.value)
          }}
          onBlur={() => {
            gotoPage(Number(showPage))
          }}
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              gotoPage(Number(showPage))
            }
          }}
        />
        of {pages}
      </div>
      <button className="next" onClick={() => gotoPage(Number(page) + 1)}>
        <SVG src="/images/icons/arrow-left.svg" />
      </button>
    </Container>
  )
}
