import React from 'react'
import { getCurrentUser } from '../../services/auth'
import { formatDatetime, formatMoney } from '../../utils/helpers'
import { invoiceUrl } from '../../utils/paths'

export default function InvoiceList(props) {
  const { invoices } = props

  const periods = Object.keys(invoices)

  return (
    <table className="table table-striped table-responsive">
      <thead>
        <tr>
          <th>Statement</th>
          <th>Date/Time</th>
          <th>Amount</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {periods.map((period) => (
          <Invoice key={period} period={period} invoice={invoices[period]} />
        ))}
      </tbody>
    </table>
  )
}

const Invoice = (props) => {
  const { period, invoice } = props

  const currentUser = getCurrentUser()

  const currencies = Object.keys(invoice.totals)

  return (
    <>
      {currencies.map((currency, index) => (
        <tr key={`${period}-${currency}`}>
          {index === 0 ? (
            <>
              <td>{period}</td>
              <td>{formatDatetime(invoice.createdAt)}</td>
              <td>{formatMoney(invoice.totals[currency], currency)}</td>
              <td>
                {/*currentUser.advertiserUuid && (
                  <a
                    href={invoiceUrl(currentUser.advertiserUuid, period)}
                    target="_blank"
                    rel="noreferrer"
                  >
                    INV#{invoice.hash.substr(0, 8).toUpperCase()}
                  </a>
                )*/}
              </td>
            </>
          ) : (
            <>
              <td></td>
              <td></td>
              <td>
                {currency} {invoice.totals[currency]}
              </td>
              <td></td>
            </>
          )}
        </tr>
      ))}
    </>
  )
}
