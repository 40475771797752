import React from 'react'
import styled from 'styled-components'
import Graph from './StatsGraph'
import { SpinnerRect } from '../shared/elements'

const Container = styled.div``

export default function Overview(props) {
  const {
    currentStats,
    previousStats,
    startDate,
    endDate,
    defaultStartDate,
    defaultEndDate,
  } = props

  const loading = currentStats === null || previousStats === null

  const graphProps = {
    stats: currentStats,
    oldStats: previousStats,
    startDate: startDate || defaultStartDate,
    endDate: endDate || defaultEndDate,
  }

  return (
    <Container>
      {loading ? (
        <div className="text-center">
          <SpinnerRect />
        </div>
      ) : (
        <div className="row">
          <div className="col-lg-6 mb-4">
            <Graph
              title="Clicks"
              type="count"
              field="gross_clicks"
              graphField="gross_clicks"
              trend={true}
              {...graphProps}
            />
          </div>

          <div className="col-lg-6 mb-4">
            <Graph
              title="Conversions"
              type="count"
              field="conversions"
              graphField="conversions"
              trend={true}
              {...graphProps}
            />
          </div>

          <div className="col-lg-6 mb-4">
            <Graph
              title="Conversion rate"
              type="percentage"
              field="conversions"
              divType="count"
              divField="gross_clicks"
              graphField="conversion_rate"
              trend={true}
              {...graphProps}
            />
          </div>

          <div className="col-lg-6 mb-4">
            <Graph
              title="Total sales"
              type="money"
              field="sale_amount"
              graphField="sale_amount"
              trend={true}
              {...graphProps}
            />
          </div>
        </div>
      )}
    </Container>
  )
}
