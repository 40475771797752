import React from 'react'
import Form from './Form'
import { Header } from '../shared/elements'

export default function Login(props) {
  const { setAuthenticated } = props

  return (
    <>
      <Header />
      <Form setAuthenticated={setAuthenticated} />
    </>
  )
}
