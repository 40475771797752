import React from 'react'
import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'
import { Router } from '@reach/router'
import Layout from '../components/layout'
import Overview from '../components/overview/Page'
import Conversions from '../components/conversions/PageList'
import Billing from '../components/billing/Page'
import Account from '../components/account/Page'
import { Head, Toast, useToast } from '../components/shared/elements'
import { AppContextProvider } from '../components/shared/contexts'
import * as paths from '../utils/paths'

const stripe = loadStripe(process.env.STRIPE_PUBLIC_KEY)

export default (props) => {
  const [message, showMessage] = useToast()
  const context = {
    showMessage,
    location: props.location,
    params: props.params,
  }

  return (
    <>
      <Head />
      <Elements stripe={stripe}>
        <AppContextProvider value={context}>
          <div>
            <Layout>
              <Router>
                <Overview path={paths.rootPath()} />
                {/*<Publisher path={paths.publisherPath(':uuid')} />
                <Publishers path={paths.publishersPath()} />
                <PublisherNew path={paths.newPublisherPath()} />
                <ContractNew
                  path={paths.newPublisherContractPath(':publisherUuid')}
                />
                <ContractEdit
                  path={paths.editPublisherContractPath(
                    ':publisherUuid',
                    ':contractUuid'
                  )}
                />
                <Shortlinks
                  path={paths.publisherContractShortlinksPath(
                    ':publisherUuid',
                    ':contractUuid'
                  )}
                />
                <ShortlinkNew
                  path={paths.newPublisherContractShortlinkPath(
                    ':publisherUuid',
                    ':contractUuid'
                  )}
                />
                <ShortlinkEdit
                  path={paths.editPublisherContractShortlinkPath(
                    ':publisherUuid',
                    ':contractUuid',
                    ':shortlinkUuid'
                  )}
                />*/}
                <Conversions path={paths.conversionsPath()} />
                <Billing path={paths.billingPath()} />
                <Account path={paths.accountPath()} />
              </Router>
            </Layout>

            <Toast message={message} />
          </div>
        </AppContextProvider>
      </Elements>
    </>
  )
}
