import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Dialog } from '../shared/elements'
import { TextField } from '../shared/fields'
import * as API from '../../services/api'

export default function FormNewUserDialog(props) {
  const { show, toggle, user, setUser } = props

  const [isSubmitting, setSubmitting] = useState(false)
  const [error, setError] = useState('')

  const form = useForm({
    defaultValues: {
      name: user.name,
      email: user.email,
      info: {
        position: user.info?.position,
        officePhone: user.info?.officePhone,
        mobilePhone: user.info?.mobilePhone,
      },
      password: '',
      passwordConfirmation: '',
    },
  })

  const onSubmit = (values) => {
    if (isSubmitting) {
      return
    }

    setError('')
    setSubmitting(true)

    API.putRequest('/advertiser/advertiser_users', {
      advertiserUser: values,
    })
      .then((response) => {
        setSubmitting(false)

        if (response?.data?.success) {
          if (response?.data?.user) {
            setUser(response.data.user)
            toggle(false)
          }
        } else {
          setError(response?.message || 'Something went wrong')
        }
      })
      .catch((error) => {
        setSubmitting(false)
        setError(error.message)
      })
  }

  const reset = (event) => {
    event.preventDefault()
    form.reset()
    toggle(false)
  }

  return (
    <Dialog show={show} toggle={toggle}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <h4 className="mt--25 mb-30">Edit user details</h4>
        <div className="row">
          <div className="col-md-6">
            <TextField
              form={form}
              validation={{ required: true }}
              type="text"
              name="name"
              label="Full name"
            />
          </div>
          <div className="col-md-6">
            <TextField
              form={form}
              validation={{ required: true }}
              type="text"
              name="info.position"
              label="Position"
            />
          </div>
          <div className="col-md-6">
            <TextField
              form={form}
              validation={{ required: true }}
              type="email"
              name="email"
              label="E-mail"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <TextField
              form={form}
              type="text"
              name="info.officePhone"
              label="Office phone number"
            />
          </div>
          <div className="col-md-6">
            <TextField
              form={form}
              type="text"
              name="info.mobilePhone"
              label="Mobile phone number"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <TextField
              form={form}
              type="password"
              name="password"
              label="New password"
              autocomplete="new-password"
            />
          </div>
          <div className="col-md-6">
            <TextField
              form={form}
              validation={{
                validate: (value) =>
                  !form.getValues().password ||
                  value === form.getValues().password,
              }}
              type="password"
              name="passwordConfirmation"
              label="New password confirmation"
              autocomplete="new-password"
            />
          </div>
        </div>

        {error && <div className="text-danger mb-3">{error}</div>}

        <div className="form-actions text-right">
          <button disabled={isSubmitting} className="btn btn-primary mr-3">
            Submit
          </button>
          <button className="btn btn-secondary" onClick={reset}>
            Cancel
          </button>
        </div>
      </form>
    </Dialog>
  )
}
