import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Dialog } from '../shared/elements'
import { TextField, CountrySelect } from '../shared/fields'
import * as API from '../../services/api'

export default function FormCompanyDialog(props) {
  const { show, toggle, advertiser, setAdvertiser } = props

  const [isSubmitting, setSubmitting] = useState(false)
  const [error, setError] = useState('')

  const form = useForm({
    defaultValues: {
      company: { ...advertiser.company },
    },
  })

  const onSubmit = (values) => {
    if (isSubmitting) {
      return
    }

    setError('')
    setSubmitting(true)

    API.putRequest('/advertiser/company', { advertiser: values })
      .then((response) => {
        setSubmitting(false)

        if (response?.data?.success) {
          setAdvertiser(response?.data?.advertiser || null)
          toggle(false)
        } else {
          setError(response?.message || 'Something went wrong')
        }
      })
      .catch((error) => {
        setSubmitting(false)
        setError(error.message)
      })
  }

  const reset = (event) => {
    event.preventDefault()
    form.reset()
    toggle(false)
  }

  return (
    <Dialog show={show} toggle={toggle}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <h4 className="mt--25 mb-30">Edit company information</h4>
        <div className="row">
          <div className="col-md-6">
            <TextField
              form={form}
              validation={{ required: true }}
              type="text"
              name="company.name"
              label="Company name"
            />
          </div>
          <div className="col-md-6">
            <TextField
              form={form}
              validation={{ required: true }}
              type="email"
              name="company.email"
              label="E-mail"
            />
          </div>
          <div className="">
            <TextField
              form={form}
              validation={{ required: true }}
              type="text"
              name="company.address"
              label="Company address"
            />
          </div>
          <div className="col-md-4">
            <TextField
              form={form}
              validation={{ required: true }}
              type="text"
              name="company.zip"
              label="Postal code"
            />
          </div>
          <div className="col-md-8">
            <TextField
              form={form}
              validation={{ required: true }}
              type="text"
              name="company.city"
              label="City"
            />
          </div>
          <div className="">
            <CountrySelect
              form={form}
              validation={{ required: true }}
              name="company.country"
              label="Country"
            />
          </div>
          <div className="col-md-6">
            <TextField
              form={form}
              validation={{ required: true }}
              type="text"
              name="company.vat"
              label="VAT number"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <TextField
              form={form}
              type="text"
              name="company.officePhone"
              label="Office phone"
            />
          </div>
          <div className="col-md-6">
            <TextField
              form={form}
              type="text"
              name="company.fax"
              label="Office fax"
            />
          </div>
        </div>

        {error && <div className="text-danger mb-3">{error}</div>}

        <div className="form-actions text-right">
          <button disabled={isSubmitting} className="btn btn-primary mr-3">
            Submit
          </button>
          <button className="btn btn-secondary" onClick={reset}>
            Cancel
          </button>
        </div>
      </form>
    </Dialog>
  )
}
