import React, { useState, useRef } from 'react'
import styled from 'styled-components'
import SVG from 'react-inlinesvg'
import { useOutsideClick } from '../shared/hooks'
import { formatStatValue } from '../../utils/helpers/stats'
import { saveValue, loadValue } from '../../utils/storage'

const Container = styled.div.attrs((props) => ({
  className: 'panel mb-4',
}))`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-left: 0;
  padding-right: 0;

  .stat {
    flex-basis: 25%;
    padding: 0 32px;
    border-right: 1px solid #eee;

    &:last-of-type {
      border-right: none;
    }

    h2 {
      font-family: var(--body-font);
      font-size: 12px;
      font-weight: 400;
      line-height: 14px;
    }

    .value {
      font-size: 24px;
      font-weight: bold;

      span {
        margin-left: 6px;
        font-size: 12px;
        font-weight: normal;
        color: var(--text-secondary);
      }
    }

    .value-previous {
      font-size: 9px;
      font-weight: 500;
      margin-left: 6px;
      color: var(--text-secondary);
    }

    .relevant-value-label {
      margin-top: 32px;
      color: var(--text-light);
      font-size: 10px;
    }
  }

  .trend {
    display: inline-block;
    padding: 2px 6px;
    border-radius: 2px;
    font-size: 9px;
    font-weight: 600;
    line-height: 14px;

    svg {
      width: 10px;
      height: 10px;
      margin-right: 4px;
      vertical-align: middle;
    }

    span {
      vertical-align: middle;
    }

    &.positive {
      color: #45cc6c;
      background: #e7f7eb;
    }

    &.negative {
      color: #f06565;
      background: #ffebeb;

      svg {
        transform: rotate(90deg);
      }
    }
  }

  .relevant-select-container {
    display: block;
    position: relative;
    width: 100%;
    margin-top: 4px;
    margin-bottom: 12px;

    > button {
      width: 100%;
      padding-bottom: 4px;
      border-bottom: 1px solid #dddfe4;
      font-family: var(--body-font);
      font-size: 12px;
      text-align: left;

      .arrow {
        position: absolute;
        top: 0px;
        right: 8px;
        width: 12px;
        margin-left: 6px;
        color: var(--text-secondary);
      }
    }

    .relevant-select {
      position: absolute;
      top: -4px;
      left: -4px;
      right: -4px;
      padding: 8px;
      border-radius: 4px;
      background: white;
      box-shadow: 0 4px 8px -4px rgba(0, 0, 0, 0.3);
      z-index: 1;
    }

    .relevant-option {
      display: block;
      width: 100%;
      padding: 2px 4px;
      border-radius: 2px;
      text-align: left;
      background: transparent;
      transition: background 0.3s;

      &:hover {
        background: var(--text-background-hover);
      }
    }
  }

  .relevant-value {
    display: inline-block;
    font-size: 20px !important;
  }

  .relevant-trend {
    position: relative;
    top: -4px;
    margin-left: 12px;
  }

  @media (max-width: 1199px) {
    padding-bottom: 0;

    .stat {
      flex-basis: 50%;
      margin-bottom: 24px;

      &:nth-of-type(2n) {
        border-right: none;
      }
    }
  }
`

const stats = {
  gross_clicks: {
    label: 'Clicks',
    type: 'count',
    field: 'gross_clicks',
  },
  unique_clicks: {
    label: 'Unique clicks',
    type: 'count',
    field: 'unique_clicks',
  },
  conversions: {
    label: 'Conversions',
    type: 'count',
    field: 'conversions',
  },
  conversion_rate: {
    label: 'Conversion rate',
    type: 'percentage',
    field: 'conversions',
    divType: 'count',
    divField: 'gross_clicks',
  },
  new_customer_rate: {
    label: 'New customer rate',
    type: 'none',
    field: 'new_customer_rate',
  },
  customer_cost: {
    label: 'Customer acquisition cost',
    type: 'money',
    field: 'revenue',
    divType: 'count',
    divField: 'conversions',
  },
  sale_amount: {
    label: 'Total sales',
    type: 'money',
    field: 'sale_amount',
  },
  payout: {
    label: 'Commission',
    type: 'money',
    field: 'payout',
  },
  profit: {
    label: 'Total profit',
    type: 'money',
    field: 'sale_amount',
    subType: 'money',
    subField: 'payout',
  },
  items_per_order: {
    label: 'Items per order',
    type: 'float',
    field: 'items_per_order',
  },
  average_value: {
    label: 'Avg. order value',
    type: 'money',
    field: 'sale_amount',
    divType: 'count',
    divField: 'conversions',
  },
  average_touchpoints: {
    label: 'Avg. touchpoints',
    type: 'float',
    field: 'average_touchpoints',
    divField: 'length',
  },
}

const storeRelevant = (key, newValue) => {
  saveValue(key, newValue)
}

const restoreRelevant = (key, defaultValue) => () => {
  const value = loadValue(key, defaultValue)
  return stats[value] ? value : defaultValue
}

const countSum = (stats, type, field) =>
  stats.reduce((sum, stat) => {
    switch (type) {
      case 'money':
      case 'float':
      case 'percentage':
        return (sum += parseFloat(stat[field]) || 0)
      case 'count':
        return (sum += parseInt(stat[field]) || 0)
      default:
        return sum
    }
  }, 0)

const calculateTotals = (currentStats, previousStats, statKey) => {
  const {
    type,
    field,
    addType,
    addField,
    subType,
    subField,
    mulType,
    mulField,
    divType,
    divField,
  } = stats[statKey]

  let total = countSum(currentStats, type, field)
  let oldTotal = countSum(previousStats, type, field)

  if (total && divField === 'length') {
    total /= currentStats.length || 1
    oldTotal /= previousStats.length || 1
  } else if (total && divField) {
    const divCount = countSum(currentStats, divType, divField)
    const oldDivCount = countSum(previousStats, divType, divField)

    if (divCount) {
      total /= divCount
    } else {
      total = 0
    }
    if (oldDivCount) {
      oldTotal /= oldDivCount
    } else {
      oldTotal = 0
    }
  }
  if (total && mulField) {
    const mulCount = countSum(currentStats, mulType, mulField)
    const oldMulCount = countSum(previousStats, mulType, mulField)

    if (mulCount) {
      total /= mulCount
    } else {
      total = 0
    }
    if (oldMulCount) {
      oldTotal /= oldMulCount
    } else {
      oldTotal = 0
    }
  }
  if (addField) {
    const addCount = countSum(currentStats, addType, addField)
    const oldAddCount = countSum(previousStats, addType, addField)

    total -= addCount || 0
    oldTotal -= oldAddCount || 0
  }
  if (subField) {
    const subCount = countSum(currentStats, subType, subField)
    const oldSubCount = countSum(previousStats, subType, subField)

    total -= subCount || 0
    oldTotal -= oldSubCount || 0
  }

  return { total, oldTotal }
}

const calculateTrend = (type, before, after, count) => {
  if (before > 0 && after > 0) {
    if (type === 'percentage') {
      return (after * 100) / (before * 100)
    } else {
      return (after / before) * 100 - 100
    }
  } else if (before === 0 && after === 0) {
    return 0
  } else if (before === 0) {
    return 100
  } else if (after === 0) {
    return -100
  } else {
    return 0
  }
}

const formatMoney = (value) => {
  value = Number(value)
  if (value > 1000000) {
    return `${(value / 1000000).toFixed(1)}M`
  } else if (value > 1000) {
    return `${(value / 1000).toFixed(1)}K`
  } else {
    return `${value.toFixed(2)}`
  }
}

export default function Totals(props) {
  const { currentStats, previousStats } = props

  const loading = currentStats === null || previousStats === null
  if (loading) {
    return null
  }

  return (
    <Container>
      <div className="stat">
        <h2>Clicks:</h2>
        <Stat
          {...{ currentStats, previousStats }}
          statKey="gross_clicks"
          relevantKey="relevant0"
          relevantDefault="profit"
        />
      </div>
      <div className="stat">
        <h2>Conversions:</h2>
        <Stat
          {...{ currentStats, previousStats }}
          statKey="conversions"
          relevantKey="relevant1"
          relevantDefault="new_customer_rate"
        />
      </div>
      <div className="stat">
        <h2>Conversion rate:</h2>
        <Stat
          {...{ currentStats, previousStats }}
          statKey="conversion_rate"
          relevantKey="relevant2"
          relevantDefault="items_per_order"
        />
      </div>
      <div className="stat">
        <h2>Total sales:</h2>
        <Stat
          {...{ currentStats, previousStats }}
          statKey="sale_amount"
          relevantKey="relevant3"
          relevantDefault="average_touchpoints"
        />
      </div>
    </Container>
  )
}

const Stat = (props) => {
  const { currentStats, previousStats, statKey, relevantKey, relevantDefault } =
    props

  const { type } = stats[statKey]

  const [relevant, setRelevant] = useState(
    restoreRelevant(relevantKey, relevantDefault)
  )
  const setRelevantValue = (value) => {
    setRelevant(value)
    storeRelevant(relevantKey, value)
  }

  const currency = currentStats[0]?.currency || 'DKK'

  const { total, oldTotal } = calculateTotals(
    currentStats,
    previousStats,
    statKey
  )

  return (
    <>
      <div className="value">
        {type === 'money' && (
          <>
            {formatMoney(total)}
            <span>{currency}</span>
          </>
        )}
        {type === 'count' && formatStatValue(total)}
        {type === 'percentage' && (
          <>
            {(total * 100).toFixed(2)}
            <span>%</span>
          </>
        )}
      </div>
      <Trend
        value={calculateTrend('money', oldTotal, total, currentStats.length)}
      />
      <span className="value-previous">
        {type === 'money' && (
          <>
            {formatMoney(oldTotal)} {currency}
          </>
        )}
        {type === 'count' && formatStatValue(oldTotal)}
        {type === 'percentage' && (
          <>
            {(oldTotal * 100).toFixed(2)}
            <span>%</span>
          </>
        )}
      </span>
      {/*
      <RelevantStat
        currentStats={currentStats}
        previousStats={previousStats}
        currency={currency}
        relevant={relevant}
        setRelevant={setRelevantValue}
        hiddenRelevant={statKey}
      />
      */}
    </>
  )
}

const RelevantStat = (props) => {
  const {
    currentStats,
    previousStats,
    currency,
    relevant,
    setRelevant,
    hiddenRelevant,
  } = props

  const { type, label } = stats[relevant]

  const [showMenu, setShowMenu] = useState(false)
  const menuRef = useRef()
  useOutsideClick(menuRef, () => setShowMenu(false))

  const { total, oldTotal } = calculateTotals(
    currentStats,
    previousStats,
    relevant
  )

  const trend = !!(total && oldTotal)

  return (
    <>
      <div className="relevant-value-label">Relevant metric</div>
      <div className="relevant-select-container" ref={menuRef}>
        <button className="a" onClick={() => setShowMenu(true)}>
          {label}
          <SVG src="/images/icons/arrow-down.svg" className="arrow" />
        </button>

        {showMenu && (
          <div className="relevant-select">
            {Object.keys(stats).map((statKey) => (
              <button
                className="a relevant-option"
                key={statKey}
                hidden={statKey === hiddenRelevant}
                onClick={() => {
                  setRelevant(statKey)
                  setShowMenu(false)
                }}
              >
                {stats[statKey].label}
              </button>
            ))}
          </div>
        )}
      </div>
      <div className="value relevant-value">
        {type === 'money' && (
          <>
            {formatMoney(total)}
            <span>{currency}</span>
          </>
        )}
        {type === 'count' && formatStatValue(total)}
        {type === 'float' && total.toFixed(2)}
        {type === 'percentage' && (
          <>
            {(total * 100).toFixed(2)}
            <span>%</span>
          </>
        )}
        {type === 'none' && 'No data'}
      </div>
      {trend && (
        <Trend
          className="relevant-trend"
          value={calculateTrend('money', oldTotal, total, currentStats.length)}
        />
      )}
    </>
  )
}

const Trend = (props) => {
  const { value, className } = props

  const shownValue = value.toFixed(2)

  if (value >= 0) {
    return (
      <span className={`trend positive ${className || ''}`}>
        <SVG src="/images/icons/trend-arrow.svg" />
        <span>{shownValue}%</span>
      </span>
    )
  } else {
    return (
      <span className={`trend negative ${className || ''}`}>
        <SVG src="/images/icons/trend-arrow.svg" />
        <span>{shownValue}%</span>
      </span>
    )
  }
}
